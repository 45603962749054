import * as styles from './industry-landing-page.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Brand from '../components/brand';
import Button from '../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import React from 'react';
import SEO from '../components/seo';
import Section from '../components/section';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { graphql } from 'gatsby';

const IndustryLandingPageTemplate = ({ data }) => {
  // Initialize page data
  const {
    industry,
    title,
    description,
    heading,
    content: rawContent,
  } = data.industryLandingPage.data;
  const customerImages = data.customerImages.edges;

  const content = JSON.parse(rawContent);
  const { sections, brands } = content;

  return (
    <>
      <SEO title={title} description={description} />
      <Header
        btnLabel={
          <>
            Get started <FontAwesomeIcon icon={faArrowRight} />
          </>
        }
        btnLink="/plans/"
        btnTheme="primary"
      />
      <main className={styles.industryLandingPage}>
        <Section theme="white" style={{ padding: `5rem 0`, marginTop: `5rem` }}>
          <div className="row align-items-center">
            <div className="col-lg-7">
              <p
                className="heading-xs-upper color-pink"
                style={{ fontSize: '18px', marginBottom: '1rem' }}>
                <strong>{industry}</strong>
              </p>
              <h1 className="color-black" style={{ marginBottom: `2.5rem` }}>
                {heading}
              </h1>
              <h2 className="text-body color-muted-foreground">{description}</h2>
              <div className={styles.buttons}>
                <Button
                  theme="primary"
                  size="large"
                  type="internal"
                  href="/plans/"
                  style={{ gap: '12px' }}>
                  Get started <FontAwesomeIcon icon={faArrowRight} />
                </Button>
                <Button
                  theme="outline"
                  size="large"
                  type="internal"
                  href="/contact/"
                  style={{ gap: '12px' }}>
                  Talk to an expert
                </Button>
              </div>
            </div>
            <div className="col-lg-5">
              <div className={styles.customerCards}>
                {brands.map((brand, index) => (
                  <div
                    key={index}
                    className={styles.customerCard}
                    style={{ '--brand-color': brand.color }}>
                    <GatsbyImage image={getImage(customerImages[index].node)} alt={brand.name} />
                    <div className={styles.customerCardBackground}></div>
                    <div className={styles.customerCardSolidBackground}></div>
                    <div className={styles.customerCardContent}>
                      <p>{brand.name}</p>
                      <p>{brand.description}</p>
                      <Button
                        theme="white"
                        href={brand.quizUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'var(--brand-color)' }}>
                        Take Quiz
                      </Button>
                    </div>
                    <div className={styles.customerCardOverlay}>
                      <p>{brand.quizTitle}</p>
                      <Button
                        theme="white"
                        size="small"
                        href={brand.quizUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'var(--brand-color)' }}>
                        Take Quiz
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Section>
        {sections.map((section, index) => (
          <Section key={index} theme={index % 2 === 0 ? 'grey' : 'white'}>
            <div className="row justify-content-between align-items-center">
              <div className={`col-lg-6 col-xl-5 order-lg-${index % 2 === 0 ? '0' : '1'}`}>
                <p
                  className="heading-xs-upper color-pink"
                  style={{ fontSize: '14px', marginBottom: '0.75rem' }}>
                  <strong>{section.topHeading}</strong>
                </p>
                <p className="heading-lg color-black">{section.heading}</p>
                <p className="heading-sm color-muted-foreground">{section.text}</p>
              </div>
              <div className={`col-lg-6 order-lg-${index % 2 === 0 ? '1' : '0'}`}>
                <div className={styles.featureImage}>
                  <GatsbyImage image={getImage(customerImages[index + 3].node)} alt="screenshot" />
                </div>
              </div>
            </div>
          </Section>
        ))}
        <Section theme="default" style={{ padding: `5rem 0` }}>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="row">
                <div className="col">
                  <p className="text-body color-black text-center">Trusted by leading businesses</p>
                </div>
              </div>
              <div className="row align-items-center justify-content-center justify-content-lg-between">
                <Brand id="gretchen-rubin" className={`col-auto ${styles.brand}`} />
                <Brand id="jennakutcher-color" className={`col-auto ${styles.brand}`} />
                <Brand id="melyssagriffin-color" className={`col-auto ${styles.brand}`} />
                <Brand id="redcross-color" className={`col-auto ${styles.brand}`} />
                <Brand id="atlassian" className={`col-auto ${styles.brand}`} />
                <Brand id="lush-color" className={`col-auto ${styles.brand}`} />
              </div>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={{ padding: `5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">
                Ready to create your own {industry.toLowerCase()} quiz?
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p
                className="heading-sm color-white text-center"
                style={{ marginTop: '0', marginBottom: '2rem' }}>
                Create your first quiz in minutes with our full-featured quiz builder and start
                connecting with your customers.
              </p>
              <Button
                theme="white"
                size="large"
                type="internal"
                href="/plans/"
                style={{ gap: '12px' }}>
                Get started <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default IndustryLandingPageTemplate;

export const pageQuery = graphql`
  query ($slug: String!, $customerImagesDirectory: String!) {
    industryLandingPage: airtable(
      table: { eq: "IndustryLandingPages" }
      data: { slug: { eq: $slug } }
    ) {
      data {
        industry
        slug
        title
        description
        heading
        content
      }
    }
    customerImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: $customerImagesDirectory }
      }
      sort: { fields: relativePath }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
