// extracted by mini-css-extract-plugin
export var brand = "industry-landing-page-module--brand--ab896";
export var buttons = "industry-landing-page-module--buttons--0f982";
export var customerCard = "industry-landing-page-module--customer-card--038d4";
export var customerCardBackground = "industry-landing-page-module--customer-card-background--19deb";
export var customerCardContent = "industry-landing-page-module--customer-card-content--b4b33";
export var customerCardOverlay = "industry-landing-page-module--customer-card-overlay--6bd6f";
export var customerCardSolidBackground = "industry-landing-page-module--customer-card-solid-background--57097";
export var customerCards = "industry-landing-page-module--customer-cards--585f9";
export var designImage = "industry-landing-page-module--design-image--535c5";
export var featureImage = "industry-landing-page-module--feature-image--4c4d5";
export var industryLandingPage = "industry-landing-page-module--industry-landing-page--0e23c";